{
  "baseUrl": "https://www.tuyanow.com",
  "apiBaseUrl": "https://api.tuyanow.com",
  "openIdBaseUrl": "https://login.tuyanow.com",
  "apiKey": "ef629bb6-80e1-4990-a01e-1207355bb005",
  "creditCardAccountName": "Tuya Driver Club LLC-CP",
  "payment2CPUrl": "https://www.tuyanow.com",
  "configuration": "PRD - Central",
  "paymentUri": "2cp",
  "buildNumber": "Release-1275",
  "Firebase_Client_apiKey": "AIzaSyB8hSLUmAC4UNnEg-w3XC0pHEX-5myIrlw",
  "Firebase_Client_authDomain": "tuyadriver.firebaseapp.com",
  "Firebase_Client_databaseURL": "https://tuyadriver.firebaseio.com",
  "Firebase_Client_projectId": "tuyadriver",
  "Firebase_Client_storageBucket": "tuyadriver.appspot.com",
  "Firebase_Client_messagingSenderId": "928377346158",
  "Firebase_Client_appId": "1:928377346158:web:9b562eaa089b8c6bdbae7f",
  "driverAndroidApp": "https://play.google.com/store/apps/details?id=com.tuyatech.driverapp",
  "driverIosApp": "https://apps.apple.com/us/app/tuya-driver/id6453935721",
  "driverIosAppName": "skype-for-iphone",
  "driverIosAppId": "304878510",
  "mobileAppDomain": "https://link-www.tuyanow.com/",
  "mobileAppAndroidDriver": "tuya-driver://",
  "mobileAppAndroidShipper": "tuya-shipper://",
  "googleMapsKey": "AIzaSyBhuFkBBhfg0NYfQMIn8HV7Sv1XjO7ncOc",
  "stripePubKey": "pk_live_51LrMExHGPITBllPaws3uMR2TbIwjVSUGYu0X74ZhVvuceoRTF8UlUBn7PJMad6YLFgGvF57DUcCS4h8Cy2PuzO4K00YKvXolxX",
  "stripeIsLive": "False"
}